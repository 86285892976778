import styled from 'styled-components'

const TitleWrapper = styled.div`
  align-items: flex-start;
  background-color: ${props => props.theme.lightGrey};
  display: flex;
  width: 100%;
  z-index: 1;
  h1 {
    flex-basis: 0;
    flex-grow: 999;
    margin-right: 1rem;
  }
  @media screen and (min-width: 992px) {
    position: sticky;
    top: 0;
  }
`

export default TitleWrapper